import React from "react";
import Layout from '../../components/layout';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ItemWithFinish from "../../components/itemWithFinish";
import { graphql } from 'gatsby';

const quickDry = ({data}) => (
	<Layout>
		<Row>
			<Col>
				<h1>Quick Dry Paints</h1>
			</Col>
		</Row> 
		<hr />
		{/*grey quick dry paint*/}
		<ItemWithFinish heading = {"Quick Dry Paints"} data={"As a primer coat for exterior & interior surface, modified alkyd fast drying & pigment based systems is used over metal alloys etc."} image={data.quickDryMain.childImageSharp.fluid} package={"20 ltr, 4ltr, 1ltr, 1/2 ltr"} color = {"Grey"} finish = {"Smooth & Glossy"} dryingTime = {"Surface dry-5 minutes, hard dry-2-3 hours."}/>
	</Layout>
)

export default quickDry;

export const query = graphql`
query {
    

	quickDryMain: file(relativePath: { eq: "quickDryMain.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

     
}`